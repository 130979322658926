<script>
import Vue from "vue";
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import "vue-select/dist/vue-select.css";
import { VueEditor } from "vue2-editor";
import "icheck-material";
import moment from "moment";
import datepicker from "@/components/Datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "JurnalForm",
    data() {
        return {
            errors: [],
            totalCoa: [],
            totalType: [],
            totalPartnetType: [
                {label: 'COURIER', type: 1}, 
                {label: 'SHIPPER', type: 2},
                {label: 'INTERNAL', type: 4},
            ],
            totalPartner: [],
            loading: false,
            roles: "",
            show: false,
            method: "POST",
            form: {
                journal_date: moment().format("DD/MM/YYYY"),
                journal_type_id: '',
                referensi: '',
                note: '',
                saldoshipper: '',
            },
            saldoshipper: '',
            formItem: {
                id: "",
                coa_id: "",
                coa_no: "",
                coa_name: "",
                debit: 0,
                credit: 0,
                partner: "",
                partner_type: "",
                partner_id: "",
            },
            type: "",
            submitting: false,
            ddt: ''
        };
    },
    components: { 
        VueEditor,
        datepicker,
        vSelect,
    },
    created: function() {
        // cek tanggal tutup buku
        authFetch("/accounting/jurnal/close_jurnal_cek")
            .then((res) => {
                res.json().then((js) => {
                    if(js.value != ' '){
                        $('#journal_date').datepicker('setStartDate', js.value);
                    }
                    else{
                    }
                    //  this.date = js.value;
                    //  return moment(this.date).format('DD/MM/YYYY');
                });
            });
        if (this.$route.params.id) {
            this.loadGroup(this.$route.params.id);
        } else {
        }

        authFetch("/shipper/coa")
        .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
        })
        .then((js) => {
            this.totalCoa = js.data;
        });

        authFetch("/accounting/jurnal/journal_type")
        .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
        })
        .then((js) => {
            this.totalType = js.data;
        });
    },
    methods: {
        // closeDate() {
        //     const self = this;
        //     // return self.date;
        //     // authFetch("/accounting/jurnal/close_jurnal")
        //     // .then((res) => {
        //     //     res.json().then((js) => {
        //     //         $("#journal_date").datepicker({
        //     //         // minDate: new Date('30/11/2014'),
        //     //         minDate: new Date('30/11/2014')});
        //     //          this.date = js.value;
        //     //          return this.date;
        //     //     });
        //     // });
        //     var dateT = '23/12/2022';
        //     return dateT;
        // },
        uncheck(e) {
            if (this.saldoshipper == e.target.value) {
                this.saldoshipper = '';
                this.form.saldoshipper = '';
                e.target.checked = false;
            } else {
                this.saldoshipper = e.target.value;
                this.form.saldoshipper = e.target.value;
                e.target.checked = true;
            }
        },
        loadGroup(id) {
            authFetch("/accounting/jurnal/group/" + id).then((res) => {
                res.json().then((json) => {
                    this.form.journal_date = json.journal_date;
                    $("#journal_date").datepicker("setDate", moment(json.journal_date).format("DD/MM/YYYY"));
                    this.form.journal_type_id = json.journal_type_id;
                    this.form.referensi = json.referensi;
                    this.form.note = json.note;
                    this.form.saldoshipper = json.bospay;
                });
            });
        },
        partnerChange(event) {
            this.formItem.partner_id = "";
            let type = event.target.value;
            this.type = type;
            if (this.type != 2) {
                this.loadPartner(type);
            } else {
                this.totalPartner = [];
            }
        },
        onSearch(search, loading) {
            if (search.length < 1 || search.length > 2 || this.type != 2) {
                loading(true);
                authFetch(`/accounting/jurnal/partner/` + this.type + `/${escape(search)}`)
                .then((res) => {
                    if (res.status === 201) {} else if (res.status === 400) {}
                    return res.json();
                })
                .then((js) => {
                    this.totalPartner = js.data;
                });
                loading(false);
            } else {
                this.totalPartner = [];
            }
        },
        loadPartner(type){
            authFetch("/accounting/jurnal/partner/"+type)
            .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
            })
            .then((js) => {
                this.totalPartner = js.data;
            });
        },
        resetFormItem()
        {
            this.formItem = {
                id: "",
                coa_id: "",
                coa_no: "",
                coa_name: "",
                debit: 0,
                credit: 0,
                partner: "",
                partner_type: "",
                partner_id: "",
            };
        },
        getFormItem()
        {
            var aData = {
                id: this.formItem.id,
                coa_id: this.formItem.coa_id,
                coa_no: this.formItem.coa_no,
                coa_name: this.formItem.coa_name,
                debit: this.formItem.debit,
                credit: this.formItem.credit,
                partner: this.formItem.partner,
                partner_type: this.formItem.partner_type,
                partner_id: this.formItem.partner_id,
            };
            return aData;
        },
        getDataItem(aData)
        {
            return {
                id: aData.id,
                coa_id: aData.coa_id,
                coa_no: aData.coa_no,
                coa_name: aData.coa_name,
                debit: aData.debit,
                credit: aData.credit,
                partner: aData.partner,
                partner_type: aData.partner_type,
                partner_id: aData.partner_id,
            };
        },
        addItem: function() {
            const e = this.$refs;
            this.resetFormItem();
            $("#update").hide();
            $("#append").show();
            $(e.formDialog).modal("show");
        },
        submitForm: function(ev) {
            const self = this;
            self.submitting = true;
            self.form.id = self.$route.params.id;
            // moment(self.form.journal_date).format("YYYY-MM-DD");
            var setmethod = self.form.id ? "PUT" : "POST";
            var items = [];
            this.tbl
                .api()
                .rows()
                .every(function(rowIdx, tableLoop, rowLoop) {
                    var d = this.data();
                    items.push(d);
                });
            
            if (items.length < 1) {
                Swal.fire("Proses gagal", `Item jurnal harus di-isi.`, "error");
                return;
            }

            let table = $('#tableitem').DataTable();
            let debit = table.column(7).data().sum();
            let credit = table.column(8).data().sum();

            if (debit != credit) {
                Swal.fire("Proses gagal", `Total debit & kredit harus sama.`, "error");
                return;
            }

            if ((debit + credit) == 0) {
                Swal.fire("Proses gagal", `Total debit & kredit tidak boleh nol.`, "error");
                return;
            }

            var allData = { group: self.form, items: items };
            var urlSubmit = "/accounting/jurnal/group";
            if (self.method == "PUT") urlSubmit = "/accounting/jurnal/group/" + self.form.id;

            Swal.fire({
            title: "Simpan?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                authFetch(urlSubmit, {
                    method: setmethod,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(allData),
                })
                    .then((res) => {
                    return res.json();
                    })
                    .then((js) => {
                    if (js.success) {
                        Swal.fire("Proses Berhasil", ``, "success");
                        this.$router.push("/report/jurnal-group");
                    } else {
                        Swal.fire("Proses gagal", ``, "error");
                    }
                    self.submitting = false;
                    });
                }
            });

            ev.preventDefault();
        },
        handleClick(e) {
            const evt = this.$refs;
            if (e.target.closest("button")) {
                var btnEl = $(e.target).closest("button").get(0);
                if (btnEl.dataset.action == "remove") {
                    var item_id = btnEl.dataset.id;
                    if(!item_id)
                    {
                        var nRow = $(e.target).closest("tr").get(0);
                        this.tbl.fnDeleteRow(nRow);
                    } else {
                        authFetch('/accounting/jurnal/item/'+item_id, {
                            method: 'DELETE',
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: 'id='+item_id,
                        })
                        .then((res) => {
                            return res.json();
                        })
                        .then((js) => {
                            if(js.success)
                            {
                                var nRow = $(e.target).closest("tr").get(0);
                                this.tbl.fnDeleteRow(nRow);
                            }
                        });
                    }
                } else if (e.target.dataset.action == "edit") {
                    var nRow = $(e.target).closest("tr").get(0);
                    var iRow = this.tbl.fnGetPosition(nRow);
                    var aData = this.tbl.fnGetData(iRow);

                    this.formItem = this.getDataItem(aData);
                    this.formItem.nRow = nRow;
                    this.loadPartner(this.formItem.partner_type);
                    $("#append").hide();
                    $("#update").show();
                    $(evt.formDialog).modal("show");
                }
                return false;
            }
        },
        saveItem: function(ev) {
            var btnId = this.submitItemType;
            if(ev.submitter)
                btnId = ev.submitter.id
            ev.preventDefault();
            if (btnId == "append") {
                this.appendItem(ev);
            } else if (btnId == "update") {
                this.updateItem(ev);
            }
        },
        appendItem: function(ev) {
            const e = this.$refs;
            var newArray = this.totalCoa.filter((el) => {
                return el.id == this.formItem.coa_id;
            });
            var coaArr = newArray[0];

            var newArray2 = this.totalPartner.filter((el) => {
                return el.id == this.formItem.partner_id;
            });
            var partnerArr = newArray2[0];

            let nRow = this.tbl.fnAddRow(this.formItem);
            var aData = this.getFormItem();
            aData.coa_no = coaArr.coa_no;
            aData.coa_name = coaArr.coa_name;
            aData.partner = partnerArr.label;

            this.tbl.fnUpdate(aData, nRow);
            $("td .btn.edit", nRow).click();
            this.resetFormItem();
            $(e.formDialog).modal("hide");
        },
        updateItem: function(ev) {
            const e = this.$refs;
            var newArray = this.totalCoa.filter((el) => {
                return el.id == this.formItem.coa_id;
            });
            var coaArr = newArray[0];

            var newArray2 = this.totalPartner.filter((el) => {
                return el.id == this.formItem.partner_id;
            });
            var partnerArr = newArray2[0];

            let nRow = this.formItem.nRow;
            var aData = this.getFormItem();
            aData.coa_no = coaArr.coa_no;
            aData.coa_name = coaArr.coa_name;
            aData.partner = partnerArr.label;

            this.tbl.fnUpdate(aData, nRow);
            $("td .btn.edit", nRow).click();
            this.resetFormItem();
            $(e.formDialog).modal("hide");
        },
    },
    mounted() {
    const e = this.$refs;
    const self = this;
        
    this.tbl = createTable(e.tableitem, {
    title: "",
    ajax: "/accounting/jurnal/item",
    serverSide: false,
    paramData: function(d) {
        if (self.$route.params.id) {
            d.group_id = self.$route.params.id;
        } else {
            d.group_id = 0;
        }
    },
    selectedRowClass: "",
    frame: false,
    displayLength: 100,
    lengthChange: false,
    filter: false,
    // order: [[ 0, "asc" ]],
    sort: false,
    processing: true,
    language: {
        zeroRecords: "Tidak ada data.",
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
    columns: [
        { data: "id", visible: false },
        { data: "partner_id", visible: false, },
        { data: "partner_type", visible: false, },
        { data: "coa_id", visible: false, },
        { data: "coa_no" },
        { data: "coa_name" },
        { data: "partner" },
        { data: "debit",
        render: function (data, type, row, meta) {
            return 'Rp ' + formatCurrency(data);
        } },
        { data: "credit",
        render: function (data, type, row, meta) {
            return 'Rp ' + formatCurrency(data);
        } },
        { data: "action",
        render: function (data, type, row, meta) {
            return `<div class="btn-group"><button type="button" class="btn btn-sm btn-outline-warning" data-action="edit" data-id="` + row.id + `"><i class="fas fa-edit"></i> Edit</button> <button type="button" class="btn btn-sm btn-outline-danger" data-action="remove" data-id="` + row.id + `"><i class="fas fa-trash"></i> Hapus</button></div>`;
        }, },
    ],
    rowCallback: function (row, data) {
    },
    footerCallback:function ( row, data, start, end, display ){
    var api = this.api(), data;
        // Total over this page
        var totaldebit;
        totaldebit = 
            api
            .column(7, { page: 'current'})
            .data()
            .reduce( function (a, b) {
                var x = parseFloat( a ) || 0;
                var y = parseFloat( b ) || 0;
                return x + y;
            }, 0);

        var totalcredit;
        totalcredit = api
            .column(8, { page: 'current'})
            .data()
            .reduce( function (a, b) {
                var x = parseFloat( a ) || 0;
                var y = parseFloat( b ) || 0;
                return x + y;
            }, 0 );

        // Update footer
            $( api.column(7).footer() ).html(
                'Rp '+ formatCurrency(totaldebit)
            );
            $( api.column(8).footer() ).html(
                'Rp '+ formatCurrency(totalcredit)
            );
    },
    }).fnEditor({
        action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>',
    });

    },
}
</script>
<template>
    <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card card-primary">
                    <form role="form" @submit.prevent="submitForm">
                        <div class="invoice p-3 mb-3">
                            <input id="id" class="form-control" v-model="form.id" type="hidden" name="id" />
                                <div class="card card-default">
                                    <div class="card-header">
                                        <h3 class="card-title"></h3>
                                        <div class="card-tools">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">TANGGAL JURNAL</label>
                                                <datepicker id="journal_date"  :options="{format: 'dd/mm/yyyy', autoclose: true, startDate: ddt}" v-model="form.journal_date" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">TIPE JURNAL</label>
                                                <v-select :options="totalType" v-model="form.journal_type_id" :reduce="opt => opt.id" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">REFERENSI</label>
                                                <input id="referensi" class="form-control" v-model="form.referensi" type="text" name="referensi" autocomplete="off" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">NOTE</label>
                                                <input id="note" class="form-control" v-model="form.note" type="text" name="note" autocomplete="off" />
                                            </div>
                                            <div class="col-md-12 form-group">
                                                <label class="control-label">KHUSUS COA BOSPAY</label><br />
                                                <label>
                                                    <input id="credit" v-model="form.saldoshipper" type="radio" name="saldoshipper" autocomplete="off" value="credit" @click="uncheck" />
                                                    Menambah Saldo Shipper
                                                </label>
                                                <label class="pl-3">
                                                    <input id="debit" v-model="form.saldoshipper" type="radio" name="saldoshipper" autocomplete="off" value="debit" @click="uncheck" />
                                                    Mengurangi Saldo Shipper
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="p-2"></div>
                            <!-- form items -->
                            <h4 class="mb-2">
                                <i class="fa fa-tags"></i> &nbsp; List Jurnal Item
                            </h4>
                            <div class="text-rightx">
                                <button type="button" v-on:click="addItem" data-action="create" class="btn btn-primary" title="Buat Baru">
                                    <i class="fas fas fa-plus"></i> Tambah Jurnal Item
                                </button>
                            </div>
                            <table class="table table-hover" ref="tableitem" id="tableitem">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>PARTNER ID</th>
                                        <th>PARTNER TYPE</th>
                                        <th>COA ID</th>
                                        <th>NO. AKUN</th>
                                        <th>NAMA AKUN</th>
                                        <th>PARTNER</th>
                                        <th>DEBIT</th>
                                        <th>KREDIT</th>
                                        <th>TINDAKAN</th>
                                    </tr>
                                </thead>
                                <tbody @click="handleClick"></tbody>
                                <tfoot>
                                <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="3"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <button type="submit" class="btn btn-primary mr-1" :disabled="!form.journal_date || !form.journal_type_id || !form.referensi || !form.note || submitting">Simpan</button>
                            <router-link
                            :to="{ path: '/report/jurnal-group' }"
                            class="btn btn-secondary"> Kembali </router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit.prevent="saveItem">
                        <div class="modal-header">
                            <h4 class="modal-title">Form Jurnal Item</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="bg-secondary p-3 mb-3 d-none">
                                <i class="fa fa-info"></i> FORM JURNAL ITEM
                            </div>
                            <div class="row">
                                <input type="hidden" name="id" id="id" v-model="formItem.id" />
                                <div class="col-md-12 form-group">
                                    <label class="control-label">COA Account</label>
                                    <v-select :options="totalCoa" v-model="formItem.coa_id" :reduce="opt => opt.id" />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="control-label">PARTNER TYPE</label>
                                    <select required v-model="formItem.partner_type" id="partner_type" name="partner_type" class="form-control" @change="partnerChange($event)">
                                        <option v-for="row in totalPartnetType" v-bind:key="row.type" v-bind:value="row.type">
                                            {{ row.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="control-label">PARTNER</label>
                                    <v-select :options="totalPartner" v-model="formItem.partner_id" :reduce="opt => opt.id"
                                        @search="onSearch">
                                    </v-select>
                                </div>
                                <div class="col-md-6 form-group" v-if="form.saldoshipper == '' || form.saldoshipper == 'debit' || formItem.coa_id != 36 || formItem.partner_type != 2">
                                    <label class="control-label">DEBIT</label>
                                    <input id="debit" class="form-control" v-model="formItem.debit" type="number" name="debit" autocomplete="off" min="0" />
                                </div>
                                <div class="col-md-6 form-group" v-if="form.saldoshipper == '' || form.saldoshipper == 'credit' || formItem.coa_id != 36 || formItem.partner_type != 2">
                                    <label class="control-label">CREDIT</label>
                                    <input id="credit" class="form-control" v-model="formItem.credit" type="number" name="credit" autocomplete="off" min="0" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" id="append" @click="submitItemType='append'" class="btn btn-primary">
                                Tambah item
                            </button>
                            <button type="submit" id="update" @click="submitItemType='update'" class="btn btn-default">
                                Update item
                            </button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                Tutup
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>